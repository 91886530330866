import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TermContractsService} from '../term-contracts/term-contracts.service';
import {EstablishmentContractsService} from '../establishment-contracts/establishment-contracts.service';
import {Grower} from '../../growers/grower.model';
import {TermContract} from '../term-contracts/term-contract.model';
import {EstablishmentContract} from '../establishment-contracts/establishment-contract.model';
import {PaginatedDataSource} from '@terravesta/phanes';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {
  TermContractNewDialogComponent
} from '../term-contracts/term-contract-new-dialog/term-contract-new-dialog.component';
import {
  EstablishmentContractNewDialogComponent
} from '../establishment-contracts/establishment-contract-new-dialog/establishment-contract-new-dialog.component';
import {AppRoutes} from '../../../app-routing.module';
import {ContractRoutes} from '../contracts-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';

@Component({
  selector: 'app-grower-contract-overview',
  templateUrl: './grower-contract-overview.component.html',
  styleUrls: ['./grower-contract-overview.component.scss']
})
export class GrowerContractOverviewComponent implements OnInit, OnChanges {

  @Input()
  grower: Grower;

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  contractRoutes = ContractRoutes;

  gaiaIconSet = GaiaIconSet;

  termContractsDataSource: PaginatedDataSource<TermContract>;
  establishmentContractsDataSource: PaginatedDataSource<EstablishmentContract>;

  // termColumns = ["code", "status", "current_price", "area", "sustainability_check_list_signed", "self_billing_signed", "menu"];
  termColumns = ["code", "status", "spot_contract", "current_price", "area", "Start_dt", "Term", "Field_ha" , "menu"];
  establishmentColumns = ["code", "status", "own_use", "menu"];

  constructor(
    private termContractsService: TermContractsService,
    private establishmentContractsService: EstablishmentContractsService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grower) {
      if (changes.grower.currentValue) {
        this.establishmentContractsDataSource = new PaginatedDataSource((params) => {
          params.search = {
            ...params.search,
            grower: {
              uuid: this.grower.id
            }
          }
          return this.establishmentContractsService.getRecords(params);
        });
        this.termContractsDataSource = new PaginatedDataSource<TermContract>((params) => {
          params.search = {
            ...params.search,
            grower: {
              uuid: this.grower.id
            }
          }
          return this.termContractsService.getRecords(params);
        });
      }
      this.termContractsDataSource.loadRecords();
      this.establishmentContractsDataSource.loadRecords();
    }
  }

  newTermContract() {
    const dialogRef = this.matDialog.open<TermContractNewDialogComponent>(TermContractNewDialogComponent,
      {
                data: {
                  record: {
                    grower: this.grower
                  }
                }
              }
      );
    dialogRef.afterClosed().subscribe(() => {
      this.termContractsDataSource.loadRecords();
    });
  }

  newEstablishmentContract() {
    const dialogRef = this.matDialog.open<EstablishmentContractNewDialogComponent>(EstablishmentContractNewDialogComponent,
      {
        data: {
          record: {
            grower: this.grower
          }
        }
      }
    );
    dialogRef.afterClosed().subscribe(() => {
      this.termContractsDataSource.loadRecords();
    });

  }

  spotcontractValue(value: any) {
    return value === true || value === "true" ? "active" : "cancel";

    // if(value == true) {
    //   return "active"
    // } else {
    //   // return "cross"
    //   return "cancel"
    // }
  }
}
