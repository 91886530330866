
<div class="container">

  <mat-card>
    <mat-card-header>
      <mat-card-title>Term Contracts</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="button-menu">
        <button mat-fab (click)="newTermContract()"><mat-icon>{{gaiaIconSet.add}}</mat-icon></button>
      </div>
      <mat-table [dataSource]="termContractsDataSource">

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef i18n="Contract Code" class="w-150">Contract Code</mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-150">
            {{ element.grower_contract_ref }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef i18n="Contract Status" class="w-50">Status</mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-50">
            {{ element.status | contractStatus }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="current_price">
          <mat-header-cell *matHeaderCellDef i18n="Contract current price" class="w-50">Current Price</mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-50">
            {{ element.current_price }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="area">
          <mat-header-cell *matHeaderCellDef i18n="ha/t">ha/t</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ spotcontractValue(element.spot_contract) === 'active' ? element.tonnage : element.intended_hectarage }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Start_dt">
          <mat-header-cell *matHeaderCellDef i18n="Start dt">Start dt</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.start_date | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Term">
          <mat-header-cell *matHeaderCellDef i18n="Term">Term</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.contract_term }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="spot_contract">
          <mat-header-cell *matHeaderCellDef i18n="spot_contract">Spot</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon
              [ngStyle]="{ color: spotcontractValue(element.spot_contract) === 'active' ? 'blue' : 'red' }">
              {{ gaiaIconSet[spotcontractValue(element.spot_contract)] }}
            </mat-icon>

          </mat-cell>



        </ng-container>


        <!--        spot_contract-->


<!--        <ng-container matColumnDef="sustainability_check_list_signed">-->
<!--          <mat-header-cell *matHeaderCellDef i18n="Area">Sustainability</mat-header-cell>-->
<!--          <mat-cell *matCellDef="let element">-->
<!--            <span style="font-weight: bold;" *ngIf="element.sustainability_check_list_signed; else not_signed">&#10003;</span>-->
<!--            <ng-template #not_signed>-->
<!--             <span style="font-weight: bold;">&#10007;</span>-->
<!--            </ng-template>-->
<!--          </mat-cell>-->
<!--        </ng-container>-->

<!--        <ng-container matColumnDef="self_billing_signed">-->
<!--          <mat-header-cell *matHeaderCellDef i18n="Area">Self Billing</mat-header-cell>-->
<!--          <mat-cell *matCellDef="let element">-->
<!--            <span style="font-weight: bold;" *ngIf="element.self_billing_signed; else not_signed">&#10003;</span>-->
<!--            <ng-template #not_signed>-->
<!--              <span style="font-weight: bold;">&#10007;</span>-->
<!--            </ng-template>-->
<!--          </mat-cell>-->
<!--        </ng-container>-->

        <ng-container matColumnDef="Field_ha">
          <mat-header-cell *matHeaderCellDef i18n="Field ha">Field ha</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.attached_field_hectarage }}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="menu">
          <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="w-75" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Term Contract menu">
              <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.TERM_CONTRACTS, element.id]">
                <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
                <span i18n="edit option from menu">Edit</span>
              </a>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="termColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: termColumns"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Establishment Contracts</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="button-menu">
        <button mat-fab (click)="newEstablishmentContract()"><mat-icon>{{ gaiaIconSet.add }}</mat-icon></button>
      </div>
      <mat-table [dataSource]="establishmentContractsDataSource">

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef i18n="Contract Code">Contract Code</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.grower_contract_ref }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef i18n="Contract Status">Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="own_use">
          <mat-header-cell *matHeaderCellDef i18n="Own Use">Own Use</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.own_use }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="menu">
          <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="w-75" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Term Contract menu">
              <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.ESTABLISHMENT_CONTRACTS, element.id]">
                <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
                <span i18n="edit option from menu">Edit</span>
              </a>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="establishmentColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: establishmentColumns"></mat-row>
      </mat-table>

    </mat-card-content>
  </mat-card>
</div>
