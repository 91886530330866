import {GaiaRecord} from '../../models/gaia-record.model';
import {Grower} from '../growers/grower.model';

export interface MiscanthusContract extends GaiaRecord {
  grower_contract_ref: string;
  grower: Grower;
  status: string;
  start_date: Date;
  end_date: Date;
  signed_on: Date;
  is_signed: boolean;
  contract_term: number;

  terminated: boolean;
  superseded: boolean;

  reason_terminated_superseded: string;
  termination_notes: string;

  intended_hectarage: number;
  attached_field_hectarage: number;
  tonnage: number;
}

export interface SupplyContract {
  self_billing_signed: boolean;
  sustainability_report_signed: boolean;
  sustainability_check_list_signed: boolean;
  base_price: number;
  original_total_ha: number;
  original_contracted_ha: number;
  ex_farm_contract: boolean;
  spot_contract: boolean;
}

export const CONTRACT_STATUSES = {
  live: {value: "live", name: "Live"},
  not_started: {value: "not_started", name: "Not Started"},
  expired: {value: "expired", name: "Expired"},
  terminated: {value: "terminated", name: "Terminated"},
  superseded: {value: "superseded", name: "Superseded"},
  not_live: {value: "not_live", name: "Awaiting Signature"},
  unknown: {value: "unknown", name: "Error"}
}
