import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {RecordFormDirective} from '../../../../components/record-form.directive';
import {TermContract} from '../term-contract.model';

@Component({
  selector: 'app-term-contract-form',
  templateUrl: './term-contract-form.component.html',
  styleUrls: ['./term-contract-form.component.scss']
})
export class TermContractFormComponent extends RecordFormDirective<TermContract> implements OnInit {
  @Input() form!: FormGroup;
  spotContractEnabled = false; // Store the toggle state
  // @Output() spotContractChanged = new EventEmitter<boolean>(); // Emit event

  ngOnInit(): void {
    if (this.form) {
      // Set initial value
      this.spotContractEnabled = this.form.get('spot_contract')?.value || false;

      this.form.get('spot_contract')?.valueChanges.subscribe((value) => {
        // this.spotContractChanged.emit(value); // Emit the change
        this.spotContractEnabled = value; // Update when toggle changes
      });
    }
  }

  hello() {
    // alert("This is HELLO")
    console.log(this.form.get("spot_contract").value)
  }
}
