import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-miscanthus-contract-form',
  templateUrl: './miscanthus-contract-form.component.html',
  styleUrls: ['./miscanthus-contract-form.component.scss']
})
export class MiscanthusContractFormComponent implements OnInit {

  @Input()
  form: FormGroup;
  // @Input() form!: FormGroup;
  @Input()
  spotContractEnabled = false; // Receive the value from parent

  constructor() { }

  ngOnInit(): void {
  }

  hello1() {
    alert("This is HELLO")
  }
}
